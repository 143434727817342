import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import BusinessImage from "../../assets/images/services/business.png"

const ItSolutionsPage = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT"
                title="Business Intelligence Consulting Services in India" />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <div className="left-sec">
                        <img src={BusinessImage} alt="Custom Software Development" className="image" />
                    </div>

                    <div className="right-sec">
                        <h1>Business Intelligence Consulting</h1>
                        <p className="mt-2">
                            Our Business Intelligence Consulting services empower businesses to harness the full potential of their data, driving informed decision-making and sustainable growth. In today's data-driven landscape, businesses need strategic insights to stay competitive and adapt to evolving market dynamics.
                        </p>
                    </div>
                </div>

                <div className="service-sub-container">
                    <h3>Key Services</h3>
                    <ul className="mt-3">
                        <li className="mt-2"><strong>BI Strategy Development</strong>: Craft a tailored BI strategy aligned with your business objectives.</li>
                        <li className="mt-2"><strong>Data Assessment and Cleansing</strong>: Ensure data accuracy, consistency, and completeness through meticulous assessment and cleansing.</li>
                        <li className="mt-2"><strong>Data Warehousing and Integration</strong>: Design and implement robust data warehouses for centralized analysis.</li>
                        <li className="mt-2"><strong>Reporting and Dashboard Development</strong>: Create intuitive reports and interactive dashboards to visualize key insights.</li>
                        <li className="mt-2"><strong>Advanced Analytics and Predictive Modeling</strong>: Utilize advanced analytics techniques to uncover hidden patterns and trends.</li>
                        <li className="mt-2"><strong>BI Tool Selection and Implementation</strong>: Identify and implement BI tools tailored to your organization's needs.</li>
                        <li className="mt-2"><strong>User Training and Adoption</strong>: Provide comprehensive training to ensure widespread adoption of BI solutions.</li>
                        <li className="mt-2"><strong>Performance Monitoring and Optimization</strong>: Continuously monitor and optimize BI systems for peak performance.</li>
                    </ul>
                    <h3 className="mt-3">How It Works</h3>
                    <p className="mt-2">
                        Our Business Intelligence consulting process begins with a thorough assessment of your business requirements and existing data infrastructure. Based on our analysis, we develop a customized BI strategy tailored to your specific needs. Our experienced consultants then collaborate closely with your team to execute the plan, from data integration to user training.
                    </p>
                    <h3 className="mt-3">Why Choose Us?</h3>
                    <p className="mt-2">
                        With a wealth of experience in BI consulting and a proven track record of successful projects across diverse industries, we are committed to delivering tangible results. Whether you seek to enhance operational efficiency, optimize marketing strategies, or uncover new revenue opportunities, we have the expertise and dedication to guide you towards success.
                    </p>
                </div>


                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query BusinessConsultingPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ItSolutionsPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ItSolutionsPage;
